import React from 'react';
import { Label } from '@naf/label';
import { CarModelType } from '../../../../../types/carModelType';

export function isNafTested(car: CarModelType) {
  const { nafRangeTests, nafChargeTests } = car;

  const testArray = [...(nafRangeTests || []), ...(nafChargeTests || [])];

  return testArray.length > 0;
}

export const NafTestedLabel = () => <Label text="Testet av NAF" variant="signature" />;
