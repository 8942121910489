import React, { useMemo, useRef } from 'react';
import styled from 'styled-components';
import { Grid, GridCol, GridRow } from '@naf/grid';
import { Text, TextVariant } from '@naf/text';
import { ButtonLink } from '@naf/button-link';
import useSelector from '../redux/typedHooks';
import AboutCar from '../components/bilguide/aboutCar/AboutCar';
import { CarVariants } from '../components/bilguide/carVariants/CarVariants';
import CarVariantData from '../components/bilguide/carVariants/CarVariantData';
import FeedbackSurvey from '../components/feedbacksurvey/FeedbackSurvey';
import SchemaArticle from '../components/structured-data/SchemaArticle';
import SchemaCar from '../components/structured-data/SchemaCar';
import CarModelImages from '../components/bilguide/carModelImages/CarModelImages';
import { isNafTested, NafTestedLabel } from '../components/bilguide/carlist/NafTestedLabel';
import MotorIcon from '../components/icons/Motor';
import { LoaderContent } from './LoaderPage';
import { CarVariant as CarVariantType } from '../../../types/carModelType';
import { scrollToRef } from '../lib/scrollToRef';
import BreadCrumb from '../components/breadcrumb/BreadCrumb';
import CarProperties from '../components/bilguide/carproperties/CarProperties';
import S from '../styles/bilguide/bilmodell/StyledBilModell';
import { useWindowLocation } from '../hooks/useWindowLocation';
import { CarModelStateType } from '../redux/modules/cars';
import { path } from './BilModell';
import { DEFAULT_IMG_URL } from '../lib/constants';
import { MetaData } from '../components/metaData/MetaData';
import { DocumentMetaType } from '../../../types/articleType';

interface Props {
  carSlug: string;
  imgSrc?: string;
}

export const NonEvModelPage = ({ carSlug, imgSrc }: Props) => {
  const car = useSelector((state) => state.cars.mappedData[carSlug]);
  const { data: carModelState }: CarModelStateType = car || { data: undefined, meta: { isUpdating: true } };

  const {
    brandName,
    modelMinimumPrice_NOK,
    vehicleVariants,
    vehicleModelPictures,
    meta,
    motorTests,
    nafRangeTests,
    nafChargeTests,
    nafArticleTest,
    maxWltp_km,
    minWltp_km,
    modelName,
  } = carModelState || {};

  const displayName = `${brandName} ${modelName}`;

  const windowPath = useWindowLocation();
  const filteredVehicleVariants: CarVariantType[] =
    useMemo(
      () => vehicleVariants && vehicleVariants.filter((e: CarVariantType) => e.vehicleMeta.transferred === null),
      [vehicleVariants],
    ) || [];

  const testArray = [
    ...(motorTests || []),
    ...(nafRangeTests || []),
    ...(nafChargeTests || []),
    ...(nafArticleTest || []),
  ];

  const variantCounts = filteredVehicleVariants?.length;
  const spesPosRef = useRef<HTMLDivElement>(null);

  if (!brandName) {
    return <LoaderContent />;
  }

  return (
    <S.Wrapper>
      <SchemaArticle
        title={meta?.name || displayName}
        description={meta?.ingress}
        imgUrl={(imgSrc && `https:${imgSrc}`) || DEFAULT_IMG_URL}
        datePublished={meta?._createdAt}
        dateModified={meta?._updatedAt}
        url={`/${path}`}
        id={carModelState.id}
      />
      <Grid>
        <GridRow>
          <S.HeaderGridCol s="12" m="12" l="12" xl="12">
            <BreadCrumb />
            <S.CarHeader>
              <S.Title>
                <Text tag="h1" variant={TextVariant.Header1}>
                  {displayName}
                </Text>
              </S.Title>
              <TitleAdditionalInfo>
                <S.IconWrapper>
                  {motorTests &&
                    motorTests.length > 0 &&
                    !!motorTests[0] &&
                    typeof motorTests[0].score !== 'undefined' && (
                      <IconSet>
                        <MotorIcon />
                        {`${motorTests[0].score}/100 av Motor`}
                      </IconSet>
                    )}
                  {isNafTested(carModelState) && (
                    <IconSet>
                      <NafTestedLabel />
                    </IconSet>
                  )}
                </S.IconWrapper>
              </TitleAdditionalInfo>
            </S.CarHeader>
          </S.HeaderGridCol>
          <SchemaCar
            name={meta?.name || displayName}
            brand={brandName}
            imgUrl={(imgSrc && `https:${imgSrc}`) || DEFAULT_IMG_URL}
            description={meta?.ingress}
            modelMinimumPrice={`${modelMinimumPrice_NOK}`}
            maximumSpeed={`${maxWltp_km}`}
            minimumSpeed={`${minWltp_km}`}
            id={carModelState.id}
          />
        </GridRow>
        <ImageGridRow>
          <GridCol s="12" m="12" l="12" xl="8">
            <CarModelImages vehicleModelPictures={vehicleModelPictures} />
          </GridCol>
          <GridCol s="12" m="12" l="12" xl="4">
            <S.SummaryArea>
              <CarProperties carSlug={carSlug} />
              {variantCounts > 1 ? (
                <ButtonLink
                  text="Se alle spesifikasjoner"
                  icon="arrow-down"
                  onClick={() => {
                    scrollToRef(spesPosRef);
                  }}
                />
              ) : null}
            </S.SummaryArea>
          </GridCol>
        </ImageGridRow>
        {(testArray.length > 0 || meta) && ( // I am not sure this will ever be false (can meta _really_ ever be falsy?)
          <GridRow>
            <GridCol s="12" m="12" l="8" xl="8">
              <AboutCar slug={carSlug} />
            </GridCol>
          </GridRow>
        )}
        <GridRow>
          <GridCol s="12" m="12" l={variantCounts > 1 ? '12' : '8'} xl={variantCounts > 1 ? '12' : '8'}>
            {variantCounts && variantCounts !== 0 ? (
              <div ref={spesPosRef} id="spesifikasjoner">
                <Text tag="h2" variant={TextVariant.Header2}>
                  Spesifikasjoner og varianter
                </Text>
                {variantCounts > 1 ? (
                  <CarVariants displayName={displayName} data={filteredVehicleVariants} />
                ) : (
                  <CarVariantData data={vehicleVariants[0]} />
                )}
              </div>
            ) : null}
          </GridCol>
        </GridRow>
        {meta && meta.meta && (
          <GridRow>
            <GridCol s="12" m="12" l="8" xl="8">
              <MetaData meta={meta.meta as DocumentMetaType} metaDisplay={meta.metaDisplay} />
            </GridCol>
          </GridRow>
        )}
        {meta && !meta?.metaDisplay.hideForm && (
          <GridRow>
            <GridCol s="12" m="12" l="8" xl="8">
              <S.FeedbackSurveyWrapper>
                <FeedbackSurvey contentUrl={windowPath} />
              </S.FeedbackSurveyWrapper>
            </GridCol>
          </GridRow>
        )}
      </Grid>
    </S.Wrapper>
  );
};

const TitleAdditionalInfo = styled(S.TitleAdditionalInfo)`
  padding-bottom: 4px;
`;

const IconSet = styled(S.IconSet)`
  &:last-child {
    padding-right: 0;
    border-right: 0;
  }
  margin-right: 15px;
  padding-right: 15px;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  margin-bottom: 8px;
`;

const ImageGridRow = styled(GridRow)``;
