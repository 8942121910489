import React from 'react';
import { DEFAULT_IMG_URL } from '../../lib/constants';

interface Props {
  name?: string;
  description?: string;
  imgUrl?: string;
  brand?: string;
  modelMinimumPrice?: string;
  modelMaximumPrice?: string;
  id?: string;
  minimumSpeed?: string;
  maximumSpeed?: string;
}

const SchemaCar = ({
  id = '',
  name = 'NAF - Norges Automobil-Forbund | NAF',
  description = '',
  modelMinimumPrice = '',
  modelMaximumPrice = '',
  brand = '',
  imgUrl = DEFAULT_IMG_URL,
  minimumSpeed = '',
  maximumSpeed = '',
}: Props) => {
  const structuredData = `{
        "@context": "http://schema.org",
		    "@type": "Car",
        "name": "${name}",
        "image": "${imgUrl}",
        "offers": {
            "@type": "AggregateOffer",
            "lowPrice": "${modelMinimumPrice}",
            ${modelMaximumPrice && `"highPrice": "${modelMaximumPrice}",`}
            "priceCurrency": "NOK"
        },
        ${description && `"description": "${description}",`}
        ${brand && `"brand": "${brand}",`}
        ${
          (minimumSpeed || maximumSpeed) &&
          `"speed": {
          "@type": "QuantitativeValue",
          ${minimumSpeed && `"minValue": "${minimumSpeed}",`}
          ${maximumSpeed && `"maxValue": "${maximumSpeed}"`}
      }`
        }
    }`;

  return (
    // eslint-disable-next-line react/no-danger
    <script type="application/ld+json" key={`carJSON-${id}`} dangerouslySetInnerHTML={{ __html: structuredData }} />
  );
};

export default SchemaCar;
