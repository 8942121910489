import React, { ReactElement } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';
import BilGuide from '../pages/BilGuide';
import ElbilGuide, { path as elbilPath } from '../pages/ElbilGuide';
import BilModell, { path as bilModellPath } from '../pages/BilModell';
import { NotFound } from '../pages/errors/NotFound';
import Test, { path as testPath } from '../pages/Test';

const BilModellRoute = (): ReactElement => {
  const match = useRouteMatch<{ carSlug?: string }>();
  const { carSlug } = match.params;
  if (!carSlug) {
    return <NotFound />;
  }
  return <BilModell key={carSlug} carSlug={carSlug} />;
};

const TestRoute = (): ReactElement => {
  const match = useRouteMatch<{ carSlug?: string; testSlug?: string }>();
  const { carSlug, testSlug } = match.params;
  if (!carSlug || !testSlug) {
    return <NotFound />;
  }
  return <Test testSlug={testSlug} />;
};

export const BilGuideRouter = (): ReactElement => {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${match.path}`}>
        <BilGuide />
      </Route>
      <Route exact path={`${match.path}/null`}>
        <NotFound />
      </Route>
      <Route exact path={`${match.path}/${elbilPath}`}>
        <ElbilGuide />
      </Route>
      <Route exact path={`${match.path}/${bilModellPath}`} component={BilModellRoute} />
      <Route exact path={`${match.path}/${testPath}`} component={TestRoute} />
    </Switch>
  );
};
