import styled from 'styled-components';
import { GridCol } from '@naf/grid';
import { breakpoints } from '@naf/theme';

// Need this empty wrapper for SchemaArticle script
const Wrapper = styled.div``;

const HeaderGridCol = styled(GridCol)`
  z-index: 2;
`;

const CarHeader = styled.div`
  width: fit-content;
  padding-right: 16px;
  margin-left: -16px;
  background-color: white;
`;

const Title = styled.div`
  padding-left: 16px;

  h1 {
    margin-bottom: 13px;
  }
`;

const TitleAdditionalInfo = styled.div`
  z-index: 10;
  margin: 0 0 -40px;
  padding: 0 0 12px 16px;

  p {
    margin-top: 0;
  }
`;

const SummaryArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  flex: 1;

  @media (max-width: ${breakpoints.l}) {
    margin: 24px 0 0;
  }

  @media (max-width: ${breakpoints.s}) {
    margin: 0;
  }
`;

const CarPrice = styled.div`
  margin: 10px 0 -20px;

  span {
    margin-top: 0;
    line-height: 40px;
    font-weight: 500;
  }

  p {
    margin: 0;
    line-height: 1;
  }
`;

const FeedbackSurveyWrapper = styled.div`
  margin-top: 32px;
`;

const IconWrapper = styled.div`
  position: relative;
  z-index: 11;
  display: flex;
  font-size: 1rem;
  line-height: 24px;
  flex-flow: row wrap;

  svg {
    width: 18px;
    margin-right: 6px;
    padding: 0;
  }
`;

const IconSet = styled.div`
  display: flex;
  margin-right: 20px;
`;

const BylineRole = styled.span`
  font-style: italic;
`;

export default {
  Wrapper,
  HeaderGridCol,
  CarHeader,
  Title,
  TitleAdditionalInfo,
  SummaryArea,
  CarPrice,
  FeedbackSurveyWrapper,
  IconWrapper,
  IconSet,
  BylineRole,
};
